@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.partners-cards {
  padding: 80px 0;
  @include desktopStyle {
    padding: get-vw(80px) 0;
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 40px 0;
  }

  &_list {
    gap: 60px;
    grid-gap: 60px;
    @include desktopStyle {
      gap: get-vw(60px);
      grid-gap: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 16px;
      grid-gap: 16px;
    }
    @media only screen and (max-width: $bp-phone) {
      display: block;
    }
  }

  &_card {
    position: relative;
    width: percent-width-flex-items(3, 60px);
    min-height: 300px;
    padding: 32px 32px 80px;
    border-radius: 8px;
    overflow: hidden;
    color: var(--gray-dark);
    text-decoration: none;
    background-color: var(--bg-blue-light-3);
    transition: background-color .3s;
    @include desktopStyle {
      width: percent-width-flex-items(3, get-vw(60px));
      min-height: get-vw(300px);
      padding: get-vw(32px) get-vw(32px) get-vw(80px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: percent-width-flex-items(2, 16px);
    }
    @media only screen and (max-width: $bp-phone) {
      display: block;
      width: 100%;
      min-height: 155px;
      padding: 24px 24px 60px;
    }

    &:not(:last-child) {
      @media only screen and (max-width: $bp-phone) {
        margin-bottom: 16px;
      }
    }

    &-image {
      position: absolute;
      right: 0;
      bottom: 0;
      width: auto;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: right bottom;
      }
    }

    &-head {
      position: relative;
      max-width: 50%;
      @media only screen and (max-width: $bp-tablet) {
        max-width: 45%;
      }

      &-title {
        line-height: 1.5;
      }
    }

    &-arr {
      position: absolute;
      left: 32px;
      bottom: 24px;
      width: 48px;
      height: 48px;
      color: var(--gray-dark);
      border-radius: 100%;
      transition: background-color .3s;
      @include desktopStyle {
        left: get-vw(32px);
        bottom: get-vw(24px);
        width: get-vw(48px);
        height: get-vw(48px);
      }
      @media only screen and (max-width: $bp-phone) {
        left: 8px;
        bottom: 16px;
      }

      &-icon {
        width: 16px;
        height: 16px;
        @include desktopStyle {
          width: get-vw(16px);
          height: get-vw(16px);
        }
      }
    }

    &:hover {
      background-color: var(--bg-blue-light-5);
      .partners-cards_card-arr {
        background: var(--white);
        @media only screen and (max-width: $bp-phone) {
          background: none;
        }
      }
    }
  }
}
