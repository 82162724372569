@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.packages-info {
  width: 322px;
  @include desktopStyle {
    width: get-vw(322px);
  }
  @media only screen and (max-width: $bp-tablet) {
    display: none;
  }

  &-item {
    padding: 16px 16px 16px 0;
    min-height: 80px;
    border-top: 1px solid var(--gray-light-3);
    &:first-child {
      min-height: 330px;
      border-top: none;
      padding: 32px 16px 16px 0;
      @include desktopStyle {
        min-height: get-vw(330px);
        padding: get-vw(32px) get-vw(16px) get-vw(16px) 0;
      }
    }
    @include desktopStyle {
      padding: get-vw(16px) get-vw(16px) get-vw(16px) 0;
      min-height: get-vw(80px);
    }
  }

  &-title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.6;
    text-align: left;
    font-family: $font-main-MD;

    & + .packages-info-text {
      padding-top: 16px;
      @include desktopStyle {
        padding-top: get-vw(16px);
      }
    }
  }

  &-icon {
    vertical-align: middle;
    cursor: pointer;
    color: var(--gray-light);
    @include desktopStyle {
      width: get-vw(20px);
      height: get-vw(20px);
    }
  }

  &-text {
    font-size: 1.4rem;
    line-height: 1.6;
    color: var(--gray);
  }

  &-tooltip {
    &:global(.react-tooltip) {
      font-size: 1.5rem;
      line-height: 1.6;
      z-index: 3;
      color: var(--gray);
      font-weight: 500;
      border-radius: 8px;
      background: var(--white);
      box-shadow:
        0 4px 6px -2px rgba(var(--gray-dark-2-rgba), 0.05),
        0 4px 16px -4px rgba(var(--gray-dark-2-rgba), 0.1);
      white-space: pre-line;
      max-width: 400px;
      padding: 8px 12px;
      @include desktopStyle {
        max-width: get-vw(400px);
        box-shadow:
          0 get-vw(4px) get-vw(6px) get-vw(-2px) rgba(var(--gray-dark-2-rgba), 0.05),
          0 get-vw(4px) get-vw(16px) get-vw(-4px) rgba(var(--gray-dark-2-rgba), 0.1);
        margin-right: get-vw(14px);
        padding: get-vw(8px) get-vw(12px);
        border-radius: get-vw(8px);
      }
      &:global(.react-tooltip__show) {
        opacity: 1;
      }
    }
  }
}

