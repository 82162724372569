@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .packages-block-recommend-text {
    color: var(--white);
  }
}

.packages-block {
  flex: 1;
  text-align: center;
  position: relative;
  border-radius: 12px;
  @media only screen and (max-width: $bp-tablet) {
    width: 100%;
    background-color: var(--gray-bg);
  }

  &.b-has-recommend-text {
    .packages-block-head {
      @media only screen and (min-width: $bp-tablet + 1) {
        padding-top: 74px;
        @include desktopStyle {
          padding-top: get-vw(74px);
        }
      }
    }
  }

  &.b-recommend {
    background: var(--bg-blue-light-2);
    .packages-block-item,
    .packages-block-bottom {
      border-color: var(--white);
    }
  }
  
  $bg-colors:
    "green_dark" var(--bg-green),
    "blue_light" var(--bg-blue-light),
    "yellow" var(--bg-yellow),
    "brown" var(--bg-brown),
    "green" var(--bg-green-2),
    "green_light" var(--bg-green-light-2),
    "blue_sky" var(--bg-blue-sky),
    "violet_light" var(--bg-violet-light),
    "brown_light" var(--bg-brown-light),
    "pink" var(--bg-pink),
    "pink_light" var(--bg-pink-light),
    "turquoise" var(--bg-turquoise);
  @each $name, $color, $labelColor in $bg-colors {
    &.bg-#{$name} {
      &.b-recommend {
        background: $color;
      }
    }
  }

  &-head,
  &-bottom {
    padding: 32px 16px;
    @include desktopStyle {
      padding: get-vw(32px) get-vw(16px);
    }

    .packages-block-price {
      margin-top: 24px;
      color: var(--blue);
      font-family: $font-main-SB;
      @include desktopStyle {
        margin-top: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 16px;
      }
    }
  }

  &-head {
    min-height: 330px;
    @include desktopStyle {
      min-height: get-vw(330px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 24px;
      min-height: 1px;
    }
  }

  &-head-bottom {
    margin-top: auto;
  }

  &-recommend-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 12px 12px 0 0;
    background: var(--bg-yellow);
    font-size: 1.6rem;
    padding: 4px;
    @media only screen and (max-width: $bp-tablet) {
      position: relative;
      width: calc(100% + 32px);
      margin: -24px -16px 32px;
    }
  }

  &-name {
    font-size: 3.2rem;
    font-family: $font-main-SB;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    color: var(--blue);
    margin-bottom: 4px;
    @include desktopStyle {
      margin-bottom: get-vw(4px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2.4rem;
      margin-bottom: 6px;
    }
  }

  &-text {
    font-size: 1.4rem;
    line-height: 1.6;
    color: var(--gray);
  }

  &-price {
    font-family: $font-main-MD;
    font-size: 2rem;
    line-height: 1.6;
  }

  &-icon {
    vertical-align: middle;
    color: var(--gray-light);
    @include desktopStyle {
      width: get-vw(16px);
      height: get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 13px;
      height: 13px;
    }
  }

  &-btn {
    margin-top: 24px;
    @include desktopStyle {
      margin-top: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 16px;
    }
    & + .packages-block-text {
      margin-top: 24px;
      @include desktopStyle {
        margin-top: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.3rem;
        margin-top: 16px;
      }
    }

    :global {
      .aiwa-element {
        & > .aiwa-element-widget + .aiwa-element-widget {
          display: none;
        }
      }
    }
  }

  &-item {
    color: var(--gray-dark);
    padding: 16px;
    border-top: 1px solid var(--gray-light-3);
    font-size: 2rem;
    @include desktopStyle {
      padding: get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-value {
    flex: 1;
  }

  &-bottom {
    border-top: 1px solid var(--gray-light-3);
  }

  &-info {
    flex: 2;
    display: none;
    text-align: left;
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }
  }

  &-info-title {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6;
    font-family: $font-main-MD;
    & + .packages-block-info-text {
      padding-top: 16px;
    }
  }

  &-info-text {
    font-size: 1.3rem;
    line-height: 1.6;
    color: var(--gray);
  }

  &-info-icon {
    vertical-align: middle;
    cursor: pointer;
    color: var(--gray-light);
    width: 16px;
    height: 16px;
  }

  &-info-tooltip {
    &:global(.react-tooltip) {
      font-size: 1.2rem;
      line-height: 1.6;
      z-index: 3;
      color: var(--gray);
      font-weight: 500;
      border-radius: 8px;
      background: var(--white);
      box-shadow:
        0 4px 6px -2px rgba(var(--gray-dark-2-rgba), 0.05),
        0 4px 16px -4px rgba(var(--gray-dark-2-rgba), 0.1);
      white-space: pre-line;
      max-width: 200px;
      padding: 8px 12px;
      &:global(.react-tooltip__show) {
        opacity: 1;
      }
    }
  }
}