@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.news {
  color: var(--gray-dark);
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  &_top {
    gap: 24px;
    grid-gap: 24px;
    margin-top: 60px;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
      margin-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin: 48px -16px 0;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 24px;
    }
  }

  &_title {
    & + .news_content {
      margin-top: 60px;
      @include desktopStyle {
        margin-top: get-vw(60px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 48px;
      }
      @media only screen and (max-width: $bp-phone) {
        margin-top: 32px;
      }
    }
  }

  &_categories {
    overflow: auto;
    position: relative;
    user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar { 
      display: none;
    }
  }

  &_categories-item {
    padding: 7px 29px;
    display: block;
    border: 1px solid transparent;
    font-family: $font-main-SB;
    font-size: 1.8rem;
    line-height: 1.3;
    color: var(--gray-light);
    transition: all 0.3s;
    border-radius: 90px;
    cursor: pointer;
    white-space: nowrap;
    @include desktopStyle {
      padding: get-vw(7px) get-vw(29px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
      padding: 5px 23px;
    }
    &:hover {
      color: var(--blue);
    }
    &.b-active {
      border-color: var(--blue);
      color: var(--blue);
    }
  }

  &_link-arrow {
    white-space: nowrap;
    @media only screen and (max-width: $bp-tablet) {
      display: none;
    }
    svg {
      @include desktopStyle {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
  }

  &_content {
    gap: 32px;
    grid-gap: 32px;
    margin-top: 48px;
    position: relative;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
      margin-top: get-vw(48px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 32px;
    }
  }

  &_not-found {
    margin-top: 80px;
    @include desktopStyle {
      margin-top: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 40px;
    }
    &:global(.rich-text) {
      :not(h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6) {
        color: var(--gray);
      }
    }
  }
}