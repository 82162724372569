@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.packages {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-title {
    margin-bottom: 60px;
    max-width: 694px;
    @include desktopStyle {
      margin-bottom: get-vw(60px);
      max-width: get-vw(694px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 32px;
    }
  }

  &-content {
    width: 100%;
    overflow: hidden;
    @media only screen and (max-width: $bp-tablet) {
      flex-direction: column;
      gap: 24px;
    }
  }

  &-block {
    flex: 1;
  }

  &.bg-gray {
    background: var(--gray-bg);
  }
}

