@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blackWhite"] {
  .pagination-by-sections_wrapper {
    background: var(--bg-blue-light-2);
  }
}

.pagination-by-sections {
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  font-size: 1.6rem;
  margin-bottom: 21px;
  @include desktopStyle {
    margin-bottom: get-vw(21px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-bottom: 24px;
    top: 78px;
    font-size: 1.3rem;
  }
  @media only screen and (max-width: $bp-phone-sm) {
    top: 68px;    
  }
  :global(.wr) {
    max-width: 1678px;
    @include desktopStyle {
      max-width: get-vw(1678px);
    }
  }

  &_wrapper {
    background: var(--white);
    padding: 14px 40px 14px 60px;
    border-radius: 90px;
    box-shadow: 0 4px 32px 0 rgba(var(--black-rgba), 0.05);
    overflow: hidden;
    gap: 12px;
    grid-gap: 12px;
    position: relative;
    @include desktopStyle {
      padding: get-vw(14px) get-vw(40px) get-vw(14px) get-vw(60px);
      border-radius: get-vw(90px);
      box-shadow: 0 get-vw(4px) get-vw(32px) 0 rgba(var(--black-rgba), 0.05);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 12px 16px 12px 24px;
      overflow: visible;
      min-height: 62px;
      margin: 0 -8px;
    }
  }

  &_content {
    &.b-active {
      .pagination-by-sections_active-link {
        color: var(--blue);
        &::after {
          transform: rotate(135deg);
          margin-top: 0;
          border-bottom-color: var(--blue);
          border-left-color: var(--blue);
        }
      }
      .pagination-by-sections_inner {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
    .pagination-by-sections_phone {
      display: none;
      font-size: 1.5rem;
      margin-top: 32px;
      @media only screen and (max-width: $bp-tablet) {
        display: flex;
      }
    }
  }

  &_inner {
    @media only screen and (max-width: $bp-tablet) {
      padding: 12px 20px 32px;  
      position: absolute;
      left: 10px;
      right: 10px;
      top: 100%;
      margin-top: 10px;
      border-radius: 12px;
      background: var(--white);
      box-shadow: 0 4px 32px 0 rgba(var(--black-rgba), 0.05);
      z-index: 10;
      max-height: 66vh;
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      transform: translateY(20px);
    }
  }

  &_active-link {
    font-family: $font-main-SB;
    color: var(--gray-dark);
    position: relative;
    display: none;
    padding-right: 16px;
    transition: all 0.3s;
    cursor: pointer;
    @media only screen and (max-width: $bp-tablet) {
      display: inline-block;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      transform: rotate(-45deg);
      width: 6px;
      height: 6px;
      border-bottom: 1px solid rgba(var(--gray-light-rgba), 0.5);
      border-left: 1px solid rgba(var(--gray-light-rgba), 0.5);
      right: 0;
      top: 50%;
      margin-top: -3px;
      transition: all 0.3s;
    }
  }

  &_list {
    gap: 20px 40px;
    grid-gap: 20px 40px;
    @include desktopStyle {
      gap: get-vw(20px) get-vw(40px);
      grid-gap: get-vw(20px) get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 0;
      grid-gap: 0;
    }
  }

  &_list-item {
    padding: 10px 0;
    @include desktopStyle {
      padding: get-vw(10px) 0;
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 14px 0;
      line-height: 1.3;
      border-bottom: 1px solid #F0F0F0;
      width: 100%;
    }
  }

  &_list-link {
    color: var(--gray);
    position: relative;
    text-decoration: none;
    @media only screen and (max-width: $bp-tablet) {
      color: var(--gray-dark);
    }
    &.b-active,
    &:hover {
      color: var(--blue);
    }
    &.b-active {
      &::after {
        opacity: 1;
      }
    }
    @media only screen and (min-width: $bp-tablet + 1) {
      &::after {
        content: '';
        position: absolute;
        display: block;
        left: -10px;
        right: -10px;
        top: 100%;
        margin-top: 25px;
        height: 3px;
        background-color: var(--blue);
        opacity: 0;
        transition: opacity 0.3s;
        @include desktopStyle {
          left: get-vw(-10px);
          right: get-vw(-10px);
          margin-top: get-vw(24px);
          height: get-vw(3px);
        }
      }
    }
  }

  &_action {
    gap: 38px;
    grid-gap: 38px;
    @include desktopStyle {
      gap: get-vw(38px);
      grid-gap: get-vw(38px);
    }
    :global(.btn) {
      font-size: 1.6rem;
      padding: 10px 24px;
      @include desktopStyle {
        padding: get-vw(10px) get-vw(24px);
      }
      @media only screen and (max-width: $bp-phone) {
        font-size: 1.4rem;
        padding: 8px 14px;
      }
    }
    :global(.btn.b-hide-mobile) {
      @media only screen and (max-width: $bp-phone) {
        display: none;
      }
    }
  }

  &_phone {
    line-height: 1.3;
    color: var(--gray-dark);
    text-decoration: none;
    gap: 8px;
    grid-gap: 8px;
    @include desktopStyle {
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: none;
    }
  }

  &_phone-icon {
    color: var(--green);
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    @include desktopStyle {
      width: get-vw(14px);
      height: get-vw(14px);
    }
    &.b-color-blue {
      color: var(--blue);
    }
  }
}
