@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .news-block_date {
    color: #E8E8E8;
  }
  .news-block_text {
    color: #151413;
  }
}

.news-block {
    width: calc(25% - 24px);
    font-family: $font-main-SB;
    border-radius: 12px;
    overflow: hidden;
    @include desktopStyle {
      width: calc(25% - get-vw(24px));
    }
    @media only screen and (max-width: $bp-tablet) {
      width: calc(50% - 16px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
      padding-bottom: 32px;
      border-bottom: 1px solid rgba(var(--gray-second-rgba), 0.4);
      border-radius: 0;
      &:first-child {
        .news-block_image {
          display: block;
        }
      }
    }

  &_image {
    display: block;
    text-decoration: none;
    outline: none;
    margin: 0 0 20px;
    border-radius: 8px;
    height: 376px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    @include desktopStyle {
      height: get-vw(376px);
      margin-bottom: get-vw(20px);
    }
    @media only screen and (max-width: $bp-tablet) {
      height: 42vw;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 16px;
      height: 73.6vw;
      max-height: 450px;
      display: none;
    }
    &:hover {
      .news_block-image-inner {
        transform: scale(1.03);
      }
    }
  }

  &_image-inner {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.35s;
    border-radius: 8px;
    overflow: hidden;
    background: var(--white-2);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: transform 0.35s;
    }
  }

  &_info {
    margin-bottom: 8px;
    gap: 12px;
    grid-gap: 12px;
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 16px;
    }
  }

  &_categories {
    line-height: 1.3;
    color: var(--blue);
    font-size: 1.6rem;
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
    }
  }

  &_text {
    font-size: 1.8rem;
    line-height: 1.5;
    color: var(--gray-dark);
    display: block;
    text-decoration: none;
    @media only screen and (max-width: 1200px) and (min-width: $bp-tablet + 1) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
      line-height: 1.3;
    }
    &:hover {
      color: var(--blue);
    }
  }

  &_date {
    font-family: $font-main-MD;
    font-size: 1.6rem;
    line-height: 1.6;
    color: var(--gray-light);
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
    }
  }
}
