@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.form-modal {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(#3A4252, 0.5);
  height: 100vh;
  padding: 20px 0;
  backdrop-filter: blur(8px);
  overflow: auto;
  @include desktopStyle {
    padding: get-vw(20px) 0;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--blue-light);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(var(--blue-rgba), 0.8);
    border-radius: 4px;
  }
  @media only screen and (max-width: $bp-tablet) {
    height: 100dvh;
  }

  &_wrapper {
    background: var(--bg-blue-light-2);
    width: 100%;
    max-width: 692px;
    padding: 56px;
    margin: auto;
    border-radius: 12px;
    position: relative;
    @include desktopStyle {
      max-width: get-vw(692px);
      padding: get-vw(56px);
      border-radius: get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding: 16px;
    }

    &.empty-title {
      @media only screen and (max-width: $bp-tablet) {
        padding-top: 66px;
      }
    }
  }

  &_btn-close {
    right: 24px;
    top: 24px;
    position: absolute;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;
    width: 24px;
    height: 24px;
    z-index: 2;
    @include desktopStyle {
      right: get-vw(24px);
      top: get-vw(24px);
      width: get-vw(24px);
      height: get-vw(24px);
    }
    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 91%;
      background: var(--gray-light);
      border-radius: 4px;
      top: 3%;
      left: 50%;
      margin-left: -1px;
      @include desktopStyle {
        width: get-vw(2px);
        margin-left: get-vw(-1px);
      }
    }
    &::after {
      transform: rotate(-45deg);
    }
    &::before {
      transform: rotate(45deg);
    }
  }

  &_title,
  &_text {
    margin-bottom: 32px;
    text-align: center;
    @include desktopStyle {
      margin-bottom: get-vw(32px);
    }
  }

  &_title {
    &:global(.h4) {
      margin-bottom: 16px;
    }
  }

  &_text {
    color: var(--gray);
  }

  // &_form {}
}