@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.autocomplete {
  position: relative;

  &_control {
    position: relative;
  }

  &_list {
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 4px;
    position: absolute;
    background: var(--white);
    z-index: 100;
    border: 1px solid var(--gray-light-3);
    max-height: 320px;
    padding: 10px;
    overflow: auto;
    border-radius: 12px;
    box-shadow: 0px 4px 6px -2px rgba(var(--gray-dark-2-rgba), 0.03), 0px 12px 16px -4px rgba(var(--gray-dark-2-rgba), 0.08);
    @include desktopStyle {
      padding: get-vw(10px);
      border-radius: get-vw(12px);
    }
    &::-webkit-scrollbar {
      width: 8px;
      @include desktopStyle {
        width: get-vw(8px);
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #EAECF0;
      border-radius: 8px;
    }
  }

  &_list-option {
    padding: 10px 42px 10px 14px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 1.6rem;
    color: var(--gray-dark);
    line-height: 1.6;
    font-family: $font-main-MD;
    border-radius: 6px;
    position: relative;
    @include desktopStyle {
      border-radius: get-vw(6px);
      padding: get-vw(10px) get-vw(42px) get-vw(10px) get-vw(14px);
    }

    &:hover,
    &.b-selected {
      background: var(--white-2);
    }
    &.b-selected {
      &::after {
        opacity: 1;
      }
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      opacity: 0;
      transition: opacity 0.3s;
      background: url('~~/app/assets/images/icons/check.svg') center center no-repeat;
      background-size: contain;
      top: 50%;
      width: 20px;
      height: 20px;
      right: 14px;
      margin-top: -10px;
      @include desktopStyle {
        width: get-vw(20px);
        height: get-vw(20px);
        right: get-vw(14px);
        margin-top: get-vw(-10px);
      }
    }
  }

  &_list-option-footnote {
    color: var(--gray);
    font-size: 1.4rem;
    margin-top: 4px;
    @include desktopStyle {
      margin-top: get-vw(4px);
    }
  }

  &_no-options {
    font-size: 1.6rem;
    color: var(--gray-dark);
    line-height: 1.6;
    font-family: $font-main-MD;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    @include desktopStyle {
      padding-top: get-vw(5px);
      padding-bottom: get-vw(5px);
    }
  }
}